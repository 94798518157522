import React from 'react';

// libraries
import { css } from '@emotion/core';
import { Fragment } from 'react';

import Head from 'components/Head';
import Markdown from 'components/Markdown';
import MainLayout from 'components/MainLayout';
import Table from 'components/Table';
import { Vars } from 'style';

import DocsLayout from './Layout';

interface Props {
  pageContext: { value: Typedef };
}

export default function TypedefDocs(props: Props) {
  const { value } = props.pageContext;

  const params = (value.params || []).filter(param => param.brief);

  return (
    <MainLayout>
      <Head title={value.id} />
      <DocsLayout title={value.id}>
        <code css={summary}>
          <Markdown>{`typedef ${value.type} ${value.name}`}</Markdown>
          {params.length ? (
            <Fragment>
              ) (
              <Markdown>
                {params
                  .map(child => {
                    const separator = child.type.endsWith('*') ? '' : ' ';
                    return child.type + separator + child.name;
                  })
                  .join(', ')}
              </Markdown>
              )
            </Fragment>
          ) : null}
        </code>
        <Markdown forceBlock>{value.brief || ''}</Markdown>
        <Markdown forceBlock>{value.details}</Markdown>
        {params.length ? (
          <Fragment>
            <h2 css={heading}>Parameters</h2>
            <Table
              css={{ width: '100%' }}
              columns={['Name', 'Description']}
              data={params.map(child => [
                <code>
                  <Markdown>{child.name || ''}</Markdown>
                </code>,
                <Markdown>{child.brief || ''}</Markdown>
              ])}
            />
          </Fragment>
        ) : null}
      </DocsLayout>
    </MainLayout>
  );
}

const heading = css`
  margin: 4rem 0 2rem;
`;

const summary = css`
  display: block;
  background-color: ${Vars.Shade700};
  border-radius: ${Vars.BorderRadius};
  box-shadow: inset 0 0 0 0.1rem ${Vars.Tint300};
  padding: 1.2rem;
  margin-bottom: 2rem;
`;
